<template>
  <div :style="dashboardContainerStyle" :class="dashboardContainerClass">

    <dashboard-component />
  </div>
</template>

<script>
import DashboardComponent from "./DashboardComponent.vue";

export default {
  components: {
    DashboardComponent,
  },
  name: "DashboardContainer",
  data() {
    return {
      loading: false,
      initDone: false,
      drawing: false,
    };
  },
  computed: {
    dashboardContainerStyle() {
      return { 
        height:"100%",
       }
    },
    dashboardContainerClass() {
      return ["dashboard-container"];
    }
  },
  watch: {},
};
</script>
