// initial state
import StorageService from '@/services/StorageService'

const state = {
  items: [],
  panelErrors: false
}

// getters
const getters = {
  items: state => state.items,
  panelErrors: state => state.panelErrors
}

// actions
const actions = {
  async index ({ commit }) {
    try {
      const response = await StorageService.index()
      commit('items', response.data.storages)
    } catch (e) {
      console.error(e)
      commit('items', [])
    }
  },
  async saveItem ({ commit }, item) {
    const action = item.id !== undefined ? 'update' : 'store'
    const payload = {
      id: item.id,
      name: item.name,
      capacity: item.capacity,
      max_temp: item.max_temp,
      max_charge: item.max_charge,
      max_discharge: item.max_discharge
    }
    let errors = false
    try {
      const response = await StorageService[action](payload)
      commit('saveItem', response.data.storage)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        console.error(e)
      }
    }

    return errors
  },
  async deleteItem ({ commit }, item) {
    try {
      await StorageService.delete(item)
      commit('deleteItem', item)
    } catch (e) {
      console.error(e)
    }
  },
  async updateGeometry ({ commit }, payload) {
    let errors = false
    try {
      const result = await StorageService.updateGeometry(payload.id, payload.geometry)

      commit('saveItem', result.data.storage)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        console.error(e)
      }
    }

    commit('panelErrors', errors)
    return errors
  },
  async updateNode ({ commit }, payload) {
    let errors = false
    try {
      const result = await StorageService.updateNode(payload.id, payload.nodeId, payload.isSupply)

      commit('saveItem', result.data.storage)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        console.error(e)
      }
    }

    commit('panelErrors', errors)
    return errors
  },
  resetPanelError ({ commit }) {
    commit('panelErrors', false)
  }
}

// mutations
const mutations = {
  items (state, items) {
    state.items = items
  },
  panelErrors (state, errors) {
    state.panelErrors = errors
  },
  saveItem (state, item) {
    const items = state.items

    const index = items.findIndex(t => t.id === item.id)
    if (index === -1) {
      items.push(item)
    } else {
      items[index] = item
    }

    state.items = [
      ...items
    ]
  },
  deleteItem (state, item) {
    const items = state.items

    state.items = items.filter(t => t.id !== item.id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
