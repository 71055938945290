<template>
  <div style="padding: 20px">
    <apexchart height="450" :options="chartOptions" :series="activeSubstationData" />
  </div>
</template>
<script>


export default {
  name: "SubstationSavingsChart",
  computed: {
    activeSubstationData() {

      const activeData = this.$store.getters["activeSubstations"];
      // console.log(activeData);
      //TODO filter data by active and check area etc...
      const chartData = [];
      let co2 = [];
      let oil = [];
      activeData.forEach((ad) => {
        co2.push(ad.save_co2);
        oil.push(ad.save_oil);
      });
        chartData.push({ name: "Einsparung gegenüber Gasheizung", data: co2 });
        chartData.push({ name: "Einsparung gegenüber Ölheizung", data: oil });

      return chartData;
    },
    chartOptions() {
      return {
        colors: ["#EE9966CC","#000000DD"],
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.$store.getters["activeSubstations"].map((a) => a.name),
          // categories: ["00595","00596","00597","00598"]
        },
        yaxis: {
          title: {
            text: "Einsparung in Tonnen CO2",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "Tonnen CO2" + val;
            },
          },
        },
      };
    },

    tooltipFormatY(value, options) {
      return `${value.toFixed(2)} ${this.chartSeries[options.seriesIndex].unit}`;
    },

  },
};
</script>
