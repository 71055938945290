<template>
  <v-row v-if="!initLoading">
    <v-col offset-md="3" md="6" cols="12">
      <v-img contain src="/images/logo/210219_STEAG_Fernwärme_Logo_RGB.svg" width="300" style="margin: 10px auto;"></v-img>
      <v-form ref="form" v-if="!isLoggedIn" v-model="valid" @submit="submit">
        <v-card>
          <v-card-text>
            <v-text-field
              :rules="[rules.required, rules.validEmail]"
              label="E-Mail"
              placeholder=" "
              v-model="email"
            ></v-text-field>
            <v-text-field
              :rules="[rules.required]"
              label="Passwort"
              type="password"
              placeholder=" "
              :input-style="{appearance: 'none'}"
              v-model="password"
            ></v-text-field>
            <v-alert color="error" type="error" v-if="error">{{ errorMessage }}</v-alert>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12" sm="6">
                <v-btn :disabled="!valid" color="primary" type="submit">
                  Anmelden
                  <v-progress-circular v-if="loading" color="white" indeterminate></v-progress-circular>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="text-sm-right">
                <v-btn :to="{ name: 'forgotPassword'}">Passwort vergessen</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
      <div v-else>
        <p class="text-h4 font-weight-light text-center"><span class="font-weight-medium">Willkommen</span> {{ username }}</p>
      </div>
    </v-col>
  </v-row>
  <spinner v-else />
</template>

<script>
import AccountService from '../../services/AccountService'
import RulesUtil from '../../util/RulesUtil'
import Spinner from '@/components/Spinner'

export default {
  name: 'Login',
  components: {
    Spinner
  },
  data () {
    return {
      valid: true,
      error: false,
      errorMessage: '',
      email: '',
      loading: false,
      initLoading: false,
      password: '',
      rules: RulesUtil
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.initLoading = true
      await AccountService.initCSRF()
      this.initLoading = false
    },
    async submit (e) {
      e.preventDefault()
      if (this.$refs.form.validate()) {
        this.loading = true
        try {
          await this.$store.dispatch('account/login', { email: this.email, password: this.password })
          this.error = false
          this.errorMessage = ''
          this.email = ''
          this.password = ''

          // unset intended route just in case
          this.$store.dispatch('resetIntendedRoute')
        } catch (err) {
          this.error = true
          if (err.response && err.response.data) {
            this.errorMessage = err.response.data.message || 'Unbekannter Fehler'
          } else {
            this.errorMessage = 'Unbekannter Fehler'
          }
        }
        this.loading = false
      }
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.state.account.isLoggedIn
    },
    username () {
      return this.$store.state.account.user.name
    }
  }
}
</script>
