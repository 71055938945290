import Vuex from 'vuex'
import Vue from 'vue'

import router from '../router'

/** modules */
import account from './modules/account'
import edge from './modules/edge'
import node from './modules/node'
import plant from './modules/plant'
import storage from './modules/storage'
import substation from './modules/substation'
import map from './modules/map'

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  state: {
    intendedRoute: undefined,
    activePanel: false,
    activePanelRef: null,
    navDrawer: true,
    activeSubstations: [],
  },
  mutations: {
    setIntendedRoute(state, route) {
      state.intendedRoute = route
    },
    unsetIntendedRoute(state) {
      state.intendedRoute = undefined
    },
    setActivePanel(state, panel) {
      state.activePanel = panel
    },
    setActivePanelRef(state, activePanelRef) {
      state.activePanelRef = activePanelRef
    },
    setNavDrawer(state, navDrawer) {
      state.navDrawer = navDrawer
    },
    addActiveSubstation(state, sub) {
      state.activeSubstations.push(sub);
    },
    removeActiveSubstation(state, sub) {
      state.activeSubstations.splice(state.activeSubstations.indexOf(sub), 1);
    },
    updateActiveSubstation(state, sub) {
      console.log(state);
      console.log(sub);
      // removeActiveSubstation(sub);
    }

  },
  actions: {
    setNavDrawer({ commit }, value) {
      commit('setNavDrawer', value)
    },
    setActivePanel({ commit }, panel) {
      commit('setActivePanel', panel)
    },
    setActivePanelRef({ state, commit }, payload) {
      // only set reference if non has been set, or if called from active panel
      if (state.activePanel === false || state.activePanel === payload.name) {
        commit('setActivePanelRef', payload.panel)
      }
    },
    intendedRoute({ commit }, route) {
      commit('setIntendedRoute', route)
    },
    resetIntendedRoute({ commit }) {
      commit('unsetIntendedRoute')
    },
    redirectToIntendedRoute({ getters, commit }) {
      const intendedRoute = getters.intendedRoute
      if (intendedRoute) {
        commit('unsetIntendedRoute')
        if (router.currentRoute.name !== intendedRoute.name) {
          router.push(intendedRoute)
        }
      } else {
        if (router.currentRoute.name !== 'home') {
          router.push({ name: 'home' })
        }
      }
    },
    toggleSubstations({ commit }, sub) {
      // console.log(sub);
      // console.log(this.state.activeSubstations.indexOf(sub));

      if (this.state.activeSubstations.indexOf(sub) > -1) {
        commit('removeActiveSubstation', sub);
        // substation.dis
      } else {
        commit('addActiveSubstation', sub);
      }
    },
    updateSubstation({ commit }, sub) {
      // console.log(sub);
      //TODO dirty because of nested mutations problem
      commit('removeActiveSubstation', sub);
      commit('addActiveSubstation', sub);
      // commit('updateActiveSubstation', sub);
    }
  },
  getters: {
    intendedRoute(state) {
      return state.intendedRoute
    },
    activePanel(state) {
      return state.activePanel
    },
    activePanelRef(state) {
      return state.activePanelRef
    },
    navDrawer: (state) => state.navDrawer,
    activeSubstations: (state) => state.activeSubstations
  },
  modules: {
    account,
    edge,
    node,
    plant,
    substation,
    storage,
    map
  },
})
