<template>
  <v-card max-width="20%" min-height="180px" max-height="400px" style="margin: 0 5px 0 5px" elevation="8">
    <v-btn
      title="save changes"
      style="position: absolute; right: 0; margin: 10px; height: 25px; width: 25px"
      color="primary"
      fab
      small
      elevation="2"
      v-on:click="sendSubstationData"
    >
      <v-icon small>mdi-content-save</v-icon>
    </v-btn>

    <v-card-title style="justify-content: center; font-size: 0.8em; margin-top:20px">
      <v-icon large color="primary" style="margin: 0 10px">mdi-home</v-icon>
      {{ substation.address }}
    </v-card-title>
    <v-card-subtitle style="font-size: 0.6em; text-align: center; padding-bottom: 8px">
      {{ substation.name }}
    </v-card-subtitle>
    <v-card-text style="display: flex; flex-direction: column; align-items: flex-end">
      <div :style="cardLineStyle">
        <label>Anschlussleistung (KW)</label>
        <input disabled style="width: 30%; direction: rtl" type="number" v-model="substation.power" />
      </div>
      <div :style="cardLineStyle">
        <label>Verbrauch 2021 (GJ)</label>
        <input disabled style="width: 30%; direction: rtl" type="number" v-model="substation.usage" />
      </div>
      <div :style="cardLineStyle">
        <label>Kosten 2021 (EUR)</label>
        <input disabled style="width: 30%; direction: rtl" type="number" v-model="substation.costs" />
      </div>
      <hr size="1" width="100%" color="#D4D4D4" />
      <div :style="cardLineStyle">
        <label>Wohnfläche (qm)</label>
        <input
          style="width: 30%; direction: rtl"
          type="number"
          min="100"
          step="100"
          v-model="substation.area"
          v-on:blur="updateSubData"
        />
      </div>
      <div :style="cardLineStyle">
        <label>Vermietet (%)</label>
        <!-- <v-icon x-small>mdi-pencil</v-icon> -->
        <input
          style="width: 30%; direction: rtl"
          type="number"
          min="10"
          max="100"
          step="10"
          required
          v-model="substation.occupied"
          v-on:blur="updateSubData"
        />
        <!-- <div style="display: flex; justify-content: space-between">
        <label>Wohneinheiten</label>
        <input
          style="width: 25%; direction: rtl"
          type="number"
          :value="units"
        />
      </div> -->
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "DashboardCard",
  props: {
    substation: Object,
  },
  created() {
    const subData = this.$store.state.substation.substationPowerData;
    const dat = subData[this.substation.id]["2021"];

    //TODO to this somewhere else
    if (!this.substation.usage) {
      this.substation.usage = dat.power_sum;
      // e.usage = dat.sum_power
    }

    if (!this.substation.costs) {
      this.substation.costs = dat.sum_cost_real;
    }
  },
  computed: {
    cardLineStyle() {
      return {
        width: "100%",
        display: "flex",
        "justify-content": "space-between",
      };
    },
  },
  methods: {
    updateSubData() {
      // if(parseInt(event.target.value) < parseInt(event.target.min)) {event.target.value = event.target.min;}
      
      this.$store.dispatch("updateSubstation", this.substation);
    },
    sendSubstationData() {
      this.$store.dispatch("substation/sendSubstationData", { id: this.substation.id, area: this.substation.area, occupied:this.substation.occupied });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>