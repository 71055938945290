export const clickStates = {
  IDLE: 0,
  PLANT_PLACE_GEOM: 1,
  PLANT_CHOOSE_RETURN: 2,
  PLANT_CHOOSE_SUPPLY: 3,
  SUBSTATION_PLACE_GEOM: 4,
  SUBSTATION_CHOOSE_RETURN: 5,
  SUBSTATION_CHOOSE_SUPPLY: 6,
  PLACE_NODE: 7,
  EDGE_CHOOSE_NODE_UP: 8,
  EDGE_CHOOSE_NODE_DOWN: 9,
  STORAGE_PLACE_GEOM: 10,
  STORAGE_CHOOSE_RETURN: 11,
  STORAGE_CHOOSE_SUPPLY: 12,
}

export const featureTypes = {
  PLANT: 0,
  SUBSTATION: 1,
  NODE: 2,
  EDGE: 3,
  STORAGE: 4
}
