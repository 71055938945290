import Api from './Api'

export default {
  index () {
    return Api().get('/nodes')
  },
  store (item) {
    return Api().post('/nodes', item)
  },
  update (item) {
    return Api().put('/nodes/' + item.id, item)
  },
  updateGeometry (id, geometry) {
    return Api().post(`/nodes/${id}/geometry`, { geometry: geometry })
  },
  delete (item) {
    return Api().delete('/nodes/' + item.id)
  }
}
