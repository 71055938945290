<template>
  <div style="padding: 20px">
    <!-- <v-select :disabled="loading" :items="years" v-model="year" :loading="loading" />
    <v-divider /> -->
    <apexchart height="500" :options="chartOptions" :series="activeSubstationData" />
  </div>
</template>

<script>
// import SubstationService from "../../../services/SubstationService";

export default {
  name: "SubstationCostAbsChart",
  computed: {
    activeSubstationData() {

      const data = this.$store.state.substation.substationPowerData;
      const activeData = this.$store.getters["activeSubstations"];
      
      //TODO filter data by active and check area etc...
      const chartData = [];
      activeData.forEach((ad) => {
        const sumAreaOcc = Math.floor(ad.area * (ad.occupied / 100));
        const sumArea = Math.floor(data[ad.id]["2021"].sum_cost_real / sumAreaOcc);
        const sumOid = { name: ad.name, data: [sumArea] };
        chartData.push(sumOid);
      });

      return chartData;
    },

    years() {
      const years = [];
      for (let i = 2022; i > 2019; i--) {
        years.push(i);
      }
      return years;
    },
    chartOptions() {
      return {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.activeSubstationData.map((a) => a.name),
          // categories: ["00595","00596","00597","00598"]
        },
        yaxis: {
          title: {
            text: "€/m²",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "€/m² " + val;
            },
          },
        },
      };
    },

    tooltipFormatY(value, options) {
      return `${value.toFixed(2)} ${this.chartSeries[options.seriesIndex].unit}`;
    },
    chartSeries() {
      return [
        { name: "00595", data: [200] },
        { name: "00596", data: [150] },
      ];
    },
  },
};
</script>
