<template>
  <div style="padding: 20px">
    <apexchart height="180" :options="chartOptions" :series="chartData" />
  </div>
</template>

<script>
//TODO rate fehlt am frontend
export default {
  name: "SubstationSavingsChart",
  props:{
    totalCosts:Array
  },
  computed: {
    chartData() {
      // const data = this.$store.state.substation.substationPowerData;
      // const activeData = this.$store.getters["activeSubstations"];
      // const chartData = [];

      // activeData.forEach((ad) => {
      //   const d = data[ad.id]["2021"];
      //   const sumOid = { name: ad.name, data: [d.costs] };
      //   chartData.push(sumOid);
      // });
      // chartData.push(chartData);

      return this.totalCosts;
    },

    chartOptions() {
      return {
        // colors: ['#008FFB', '#00E396', '#00E300'],
        //TODO real values
        labels: ['00595 in EUR', '00596 in EUR', '00597 in EUR', '00598 in EUR'],
        chart: {
          type: "pie",
        },
        stroke: {
          colors: ["#fff"],
        },
        fill: {
          opacity: 0.8,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },

    tooltipFormatY(value, options) {
      return `${value.toFixed(2)} ${this.chartSeries[options.seriesIndex].unit}`;
    },
  },
};
</script>
