import Vue from 'vue'
import VueI18n from 'vue-i18n'

// vuetify locales
import de from 'vuetify/lib/locale/de'
import en from 'vuetify/lib/locale/en'
import it from 'vuetify/lib/locale/it'

Vue.use(VueI18n)

const DEFAULT_LANG = 'de'

const locales = {
  de: {
    $vuetify: {
      ...de,
    },
    ...require('./i18n/de.json'),
  },
  en: {
    $vuetify: {
      ...en
    },
    ...require('./i18n/en.json')
  },
  it: {
    $vuetify: {
      ...it
    }
  }
}

const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  fallbackLocale: 'de',
  messages: locales,
})

export default i18n
