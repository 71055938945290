export default {
  required (value) {
    if (!value || value.length === 0) {
      return 'Erforderlich'
    }
    return true
  },
  validEmail (value) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!value || !pattern.test(value)) {
      return 'Ungültige E-Mail Addresse'
    }
    return true
  },
  validColor (value) {
    const pattern = /^#[0-9A-F]{6}([0-9A-F]{2})?$/i

    if (!value || !pattern.test(value)) {
      return 'Ungültige Farbe'
    }

    return true
  },
  validOptionalEmail (value) {
    if (!value || value.length === 0) {
      return true
    }
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!value || !pattern.test(value)) {
      return 'Ungültige E-Mail Addresse'
    }
    return true
  },
  validOptionalMobilePhone (value) {
    if (!value || value.length === 0) {
      return true
    }
    const pattern = /^(0043)\d+$/
    if (!pattern.test(value)) {
      return 'Ungültige Handynummer (Format: 0043123456789)'
    }
    return true
  },
  validPassword (value) {
    if (!value || value.length < 8) {
      return 'Min 8 Zeichen'
    }
    return true
  },
  validOptionalPassword (value) {
    if (!value || value.length === 0) {
      return true
    }
    if (value.length < 8) {
      return 'Min 8 Zeichen falls Passwort geändert werden soll'
    }
    return true
  },
  validOptionalInteger (value) {
    if (!value || value.length === 0) {
      return true
    }
    if (+value === parseInt(value)) {
      return true
    }
    return 'Keine gültige Ganzzahl'
  },
  validOptionalFloat (value) {
    if (!value || value.length === 0) {
      return true
    }
    if (+value === parseFloat(value)) {
      return true
    }
    return 'Keine gültige Zahl'
  },
  identicalValues (a, b, field) {
    if (a !== b) {
      return 'Muss identisch mit "' + field + '" sein.'
    }
    return true
  },
  minLength (length) {
    return (value) => {
      if (!value || value.length < length) {
        return `Muss zumindest eine Länge von ${length} haben.`
      }
      return true
    }
  },
  maxLength (length) {
    return (value) => {
      if (!value || value.length > length) {
        return `Darf maximal eine Länge von ${length} haben.`
      }
      return true
    }
  },
  maxOptionalLength (length) {
    return (value) => {
      if (!value || value.length === 0) {
        return true
      }
      if (value.length > length) {
        return `Darf maximal eine Länge von ${length} haben.`
      }
      return true
    }
  },
  validTime (value) {
    if (!value || value.length === 0) {
      return true
    }
    const pattern = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/
    if (!pattern.test(value)) {
      return 'Ungültige Uhrzeit (Format: 14:00)'
    }
    return true
  },
  validRegex (pattern) {
    if (!pattern || pattern.length === 0) {
      return true
    }
    const parts = pattern.split('/')
    let regex = pattern
    let options = ""

    if (parts.length > 1) {
      regex = parts[1]
      options = parts[2]
    }

    try {
      RegExp(regex, options)
      return true
    } catch {
      return 'Ungültiges Regex.'
    }
  }
}
