<template>
  <div>
    {{ username }}
    <v-menu bottom left>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          icon
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list style="min-width: 200px">
        <v-list-item @click="logout" class="user-info-item">
          <v-list-item-title>Abmelden</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: 'UserInfoHead',
    data () {
      return {
      }
    },
    methods: {
      logout () {
        this.$store.dispatch('account/logout')
        this.$router.push({ name: 'login' })
      }
    },
    computed: {
      username () {
        return this.$store.state.account.user.name
      }
    }
  }
</script>
