<template>
  <v-app>
    <v-app-bar app hide-on-scroll>
      <v-app-bar-nav-icon v-if="isLoggedIn" @click.stop="navDrawer = !navDrawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        {{ projectName }}
      </v-toolbar-title>
      <v-spacer />
      <user-info-head v-if="isLoggedIn"></user-info-head>
      <v-btn :to="{ name: 'login' }" text v-if="!isLoggedIn && $router.currentRoute.name !== 'login'">Login</v-btn>
    </v-app-bar>
    <v-navigation-drawer width="300" v-model="navDrawer" v-if="isLoggedIn" app>
      <v-img contain src="/images/logo/210219_STEAG_Fernwärme_Logo_RGB.svg" class="ma-9" alt="Steag"></v-img>
      <navigation-list></navigation-list>
    </v-navigation-drawer>
    <v-main>
      <template v-if="!loading">
        <router-view />
      </template>
      <template v-else>
        <spinner></spinner>
      </template>
    </v-main>
  </v-app>
</template>

<script>
import UserInfoHead from "./misc/UserInfoHead";
import NavigationList from "./misc/NavigationList";
import Spinner from "@/components/Spinner";

export default {
  name: "App",
  components: {
    UserInfoHead,
    NavigationList,
    Spinner,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    projectName() {
      return "Steag Kundendashbord";
    },
    navDrawer: {
      get() {
        return this.$store.getters.navDrawer;
      },
      set(value) {
        this.$store.dispatch("setNavDrawer", value);
      },
    },
    isLoggedIn() {
      return this.$store.getters["account/isLoggedIn"];
    },
    userSettings() {
      return this.$store.getters["account/userSettings"];
    },
  },
  watch: {
    userSettings(newValue, oldValue) {
      if (newValue.locale !== oldValue.locale) {
        this.updateLocale();
      }
    },
    isLoggedIn(value) {
      if (value) {
        this.redirectToIntended();
        this.$store.dispatch("substation/index");
        const subFilter = [
          { name: "00595", id: 15, save_co2: -13.8, save_oil: -28.6 },
          { name: "00596", id: 16, save_co2: -14.4, save_oil: -29.9 },
          { name: "00597", id: 17, save_co2: -13.2, save_oil: -27.4 },
          { name: "00598", id: 18, save_co2: -15.5, save_oil: -32.3 },
        ];
        //TODO hack to avoid concurrency loading issues
        subFilter.forEach((s) => {
          this.$store.dispatch("substation/loadData", { year: 2021, id: s.id, name: s.name });
        });
      }
    },
  },
  mounted() {
    this.refreshLogin();
  },
  methods: {
    async refreshLogin() {
      this.loading = true;
      await this.$store.dispatch("account/refresh");
      this.loading = false;
    },
    redirectToIntended() {
      if (this.isLoggedIn) {
        this.$store.dispatch("redirectToIntendedRoute");
      }
    },
    updateLocale() {
      this.$root.$i18n.locale = this.userSettings.locale;
      window.Apex.chart.defaultLocale = this.userSettings.locale;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/main.scss";
@import url("https://fonts.googleapis.com/css?family=Jost:100,300,400,500,700,900");
$body-font-family: "Jost";
$title-font: "Jost";
.v-application {
  font-family: $body-font-family, sans-serif !important;
  .title {
    // To pin point specific classes of some components
    font-family: $title-font, sans-serif !important;
  }
}
</style>
