import Api from './Api'

export default {
  index () {
    return Api().get('/edges')
  },
  store (item) {
    return Api().post('/edges', item)
  },
  update (item) {
    return Api().put('/edges/' + item.id, item)
  },
  updateNode (id, nodeId, up) {
    return Api().post(`/edges/${id}/node`, { node_id: nodeId, up })
  },
  delete (item) {
    return Api().delete('/edges/' + item.id)
  }
}
