// initial state
import EdgeService from '@/services/EdgeService'

const state = {
  items: [],
  panelErrors: false
}

// getters
const getters = {
  items: state => state.items,
  panelErrors: state => state.panelErrors
}

// actions
const actions = {
  async index ({ commit }) {
    try {
      const response = await EdgeService.index()
      commit('items', response.data.edges)
    } catch (e) {
      console.error(e)
      commit('items', [])
    }
  },
  async saveItem ({ commit }, item) {
    const action = item.id !== undefined ? 'update' : 'store'
    const payload = {
      id: item.id,
      name: item.name,
      htc: item.htc,
      diameter: item.diameter,
      length: item.length,
      roughness: item.roughness,
      supply: item.supply
    }
    let errors = false
    try {
      const response = await EdgeService[action](payload)
      commit('saveItem', response.data.edge)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        console.error(e)
      }
    }

    return errors
  },
  async updateNode ({ commit }, item) {
    let errors = false
    try {
      const response = await EdgeService.updateNode(item.id, item.nodeId, item.up)
      commit('saveItem', response.data.edge)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        console.error(e)
      }
    }

    return errors
  },
  async deleteItem ({ commit }, item) {
    try {
      await EdgeService.delete(item)
      commit('deleteItem', item)
    } catch (e) {
      console.error(e)
    }
  }
}

// mutations
const mutations = {
  items (state, items) {
    state.items = items
  },
  panelErrors (state, errors) {
    state.panelErrors = errors
  },
  saveItem (state, item) {
    const items = state.items

    const index = items.findIndex(t => t.id === item.id)
    if (index === -1) {
      items.push(item)
    } else {
      items[index] = item
    }

    state.items = [
      ...items
    ]
  },
  saveItems (state, newItems) {
    const items = state.items

    for (const newItem of newItems) {
      const index = items.findIndex(t => t.id === newItem.id)
      if (index === -1) {
        items.push(newItem)
      } else {
        items[index] = newItem
      }
    }
    state.items = [
      ...items
    ]
  },
  deleteItem (state, item) {
    const items = state.items

    state.items = items.filter(t => t.id !== item.id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
