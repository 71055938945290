// initial state
import SubstationService from '@/services/SubstationService'

// let substationDataCache = [];

const state = {
  //TODO unify items and data
  items: [],
  substationPowerData: {},
  activeSubstationData: {},
  panelErrors: false
}

// getters
const getters = {
  items: state => state.items,
  substationData: state => state.substationPowerData,
  panelErrors: state => state.panelErrors
}

// actions
const actions = {
  async index({ commit }) {
    try {
      const response = await SubstationService.index()
      commit('items', response.data.substations)
    } catch (e) {
      console.error(e)
      commit('items', [])
    }
  },
  async saveItem({ commit }, item) {
    const action = item.id !== undefined ? 'update' : 'store'
    const payload = {
      id: item.id,
      name: item.name,
      address: item.address,
      power: item.power,
      customer_id: item.customer_id
    }
    let errors = false
    try {
      const response = await SubstationService[action](payload)
      commit('saveItem', response.data.substation)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        console.error(e)
      }
    }

    return errors
  },
  async deleteItem({ commit }, item) {
    try {
      await SubstationService.delete(item)
      commit('deleteItem', item)
    } catch (e) {
      console.error(e)
    }
  },
  async updateGeometry({ commit }, payload) {
    let errors = false
    try {
      const result = await SubstationService.updateGeometry(payload.id, payload.geometry)

      commit('saveItem', result.data.substation)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        console.error(e)
      }
    }

    commit('panelErrors', errors)
    return errors
  },
  async updateNode({ commit }, payload) {
    let errors = false
    try {
      const result = await SubstationService.updateNode(payload.id, payload.nodeId, payload.isSupply)

      commit('saveItem', result.data.substation)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        console.error(e)
      }
    }

    commit('panelErrors', errors)
    return errors
  },
  async sendSubstationData({ commit }, payload) {
    let errors = false
    // console.log(payload)
    try {
      await SubstationService.updateArea(payload.id, payload.area, payload.occupied);

      // commit('saveItem', result.data.substation)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        console.error(e)
      }
    }

    commit('panelErrors', errors)
    return errors
  },
  resetPanelError({ commit }) {
    commit('panelErrors', false)
  },
  async loadData({ commit }, data) {
    try {
      // console.log(state.substationPowerData);
      if (!state.substationPowerData[data.id]) {
        // console.log(state.substationPowerData);
        const response = await SubstationService.power(data.id, data.year);
        const response2 = await SubstationService.costs(data.id, data.year);
        commit('addSubstationData', { i: data.id, y: data.year, d: response.data, n: data.name, c: response2.data })
      }
    } catch (e) {
      console.error(e)
    }
  },
}

// mutations
const mutations = {
  items(state, items) {
    state.items = items
  },
  panelErrors(state, errors) {
    state.panelErrors = errors
  },
  saveItem(state, item) {
    const items = state.items

    const index = items.findIndex(t => t.id === item.id)
    if (index === -1) {
      items.push(item)
    } else {
      items[index] = item
    }

    state.items = [
      ...items
    ]
  },
  deleteItem(state, item) {
    const items = state.items

    state.items = items.filter(t => t.id !== item.id)
  },
  addSubstationData(state, data) {
    if (!state.substationPowerData[data.i]) {
      const sum_power = data.d.data.map(c => Math.floor(parseFloat(c.power))).reduce((p, c) => p + c) * 24;
      // console.log(data);
      // const sum_cost_real = data.c.real.map(c => Math.floor(parseFloat(c.cost_real))).reduce((p, c) => p + c) * 24;
      const sum_cost_real = Math.floor(data.c.real[data.c.real.length - 1].cost_real);
      let dat = {};
      dat.name = data.n;
      dat[data.y] = {};
      dat[data.y]["power"] = data.d;
      dat[data.y]["costs"] = data.c;
      dat[data.y]["sum_power"] = sum_power;
      dat[data.y]["sum_cost_real"] = sum_cost_real;
      dat[data.y]["power_sum"] = Math.floor(parseFloat(data.d.power_sum));
      // console.log(dat); 
      state.substationPowerData[data.i] = dat;
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
