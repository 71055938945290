<template>
  <v-container style="margin: 0; height: 100%; max-width: 100%">
    <v-row style="margin: 0 0 10px 0; width: 100%; max-height: 30%; min-height: 225px">
      <v-col cols="6">
        <v-card elevation="10" height="100%">
          <v-card-title class="bg-orange-txt-white"> Übersicht </v-card-title>
          <v-row no-gutters style="padding: 5px">
            <v-col cols="6" style="display: flex; justify-content: space-between; flex-direction: column">
              <v-card style="margin: 5px; padding: 5px; display: flex; justify-content: space-between">
                <div>Gesamtverbraucht in kWh:</div>
                <div>{{ kpis.totalPowerUsage }}</div></v-card
              >
              <v-card style="margin: 5px; padding: 5px; display: flex; justify-content: space-between">
                <div>Gesamtkosten in EUR:</div>
                <div>{{ kpis.totalCosts }}</div>
              </v-card>
              <v-card style="margin: 5px; padding: 5px; display: flex; justify-content: space-between">
                <div>Gesamtkosten in EUR pro m²:</div>
                <div>{{ kpis.totalCostsSqm }}</div>
              </v-card>
              <v-card style="margin: 5px; padding: 5px; display: flex; justify-content: space-between">
                <div>Durchschnittskosten:</div>
                <div>{{ kpis.avgCosts }}</div>
              </v-card>
              <!-- <v-card style="margin: 5px; padding: 5px; display: flex; justify-content: space-between">
                <div>Durchschnittskosten pro m²:</div>
                <div>{{ kpis.avgCosts_m }}</div>
              </v-card> -->
            </v-col>
            <v-col cols="6" v-if="kpis.allCosts.length > 0" style="justify-content:center">
               <substation-total-pie-chart :totalCosts="kpis.allCosts" />
               <h5 style="text-align: center">Kosten in EUR</h5>
               </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="6" style="max-height: 500px; overflow: hidden; display: flex; justify-content: center">
        <v-card elevation="10" height="100%" style="overflow: hidden">
          <v-card-title class="bg-orange-txt-white">Karte</v-card-title>
          <!-- //TODO height -->
          <v-img height="80%" src="/images/dashboard/customer_buildings.png" />
        </v-card>
      </v-col>
    </v-row>
    <v-row :style="dashboardHeadSectionStyle">
      <dashboard-card v-for="(sub, i) in activeSubstations" :key="i" :substation="sub" />
    </v-row>
    <v-row dense style="margin: 0">
      <v-col :cols="columns" v-if="activeSubstations.length > 0">
        <v-card elevation="10" height="100%">
          <v-card-title class="bg-orange-txt-white">Verbrauch</v-card-title>
          <substation-usage-abs-chart />
          <!-- <v-card-text>
            Differenz zwischen Vorhersage und tatsächlichem Verbrauch kumuliertin Euro. Im Vergleich zu den tatsächlich
            geleisteteten (zu leistenden) Abschlägen.
          </v-card-text> -->
        </v-card>
      </v-col>
      <v-col :cols="columns" v-if="activeSubstations.length > 0">
        <v-card elevation="10" height="100%">
          <v-card-title class="bg-orange-txt-white"> Kosten </v-card-title>
          <substation-cost-abs-chart />
          <!-- <v-card-text>Kosten pro Objekt in Euro auf das Jahr gerechnet </v-card-text> -->
        </v-card>
      </v-col>
    </v-row>
    <v-row dense style="margin: 0; display: flex; max-height: 600px">
      <!-- <v-col :cols="columns" style="max-height: 100%">
        <v-card elevation="10" height="100%">
          <v-card-title class="bg-orange-txt-white"> Guthaben </v-card-title>
          <img width="100%" src="/images/dashboard/Picture3.png" />
          <v-card-text> Differenz zwischen Vorhersage und tatsächlichem Verbrauch kumuliertin Euro </v-card-text>
        </v-card>
      </v-col> -->
      <v-col :cols="columns" v-if="activeSubstations.length > 0">
        <v-card elevation="10" height="100%">
          <v-card-title class="bg-orange-txt-white"> CO2 Einsparungen </v-card-title>
          <substation-savings-chart />

          <!-- <v-card-text>Einsparungen durch Fernwärme </v-card-text> -->
        </v-card>
      </v-col>
      <v-col :cols="columns" v-if="activeSubstations.length > 0">
        <v-card elevation="10" height="100%">
          <v-card-title class="bg-orange-txt-white"> Guthaben </v-card-title>
          <substation-balance-chart />

          <!-- <v-card-text>Einsparungen durch Fernwärme </v-card-text> -->
        </v-card>
      </v-col>
    </v-row>
    <v-row dense style="margin: 0; display: flex; max-height: 600px">
      <v-col :cols="columns" v-for="(sub, i) in activeSubstations" :key="i">
        <v-card elevation="10" height="100%">
          <v-card-title class="bg-orange-txt-white"> Historie OID: {{ sub.name }} </v-card-title>
          <substation-costs-chart :item="sub" />
          <!-- <v-card-text>
            Differenz zwischen Vorhersage und tatsächlichem Verbrauch kumuliertin Euro. Im Vergleich zu den tatsächlich
            geleisteteten (zu leistenden) Abschlägen.
          </v-card-text> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardCard from "./components/DashboardCard.vue";
import SubstationCostsChart from "./components/SubstationCostsChart.vue";
import SubstationUsageAbsChart from "./components/SubstationUsageAbsChart.vue";
import SubstationCostAbsChart from "./components/SubstationCostAbsChart.vue";
import SubstationSavingsChart from "./components/SubstationSavingsChart.vue";
import SubstationTotalPieChart from "./components/SubstationTotalPieChart.vue";
import SubstationBalanceChart from "./components/SubstationBalanceChart.vue";
export default {
  name: "DashboardComponent",
  components: {
    DashboardCard,
    SubstationCostsChart,
    SubstationCostAbsChart,
    SubstationUsageAbsChart,
    SubstationSavingsChart,
    SubstationTotalPieChart,
    SubstationBalanceChart,
  },
  data() {
    return {
      kpis: {
        totalPowerUsage: 0,
        totalCosts: 0,
        totalCostsSqm: 0,
        avgCosts: 0,
        allCosts: [],
        avgCosts_m:0,
      },
      loading: false,
      initDone: false,
      columns: 6,
    };
  },
  computed: {
    activeSubstations() {
      const subs = this.$store.getters["activeSubstations"];
      if (subs) {
        subs.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      }
      return subs;
    },
    dashboardHeadSectionStyle() {
      return {
        margin: "0 0 10px 0",
        width: "100%",
        "max-height": "20%",
        "min-height": "225px",
      };
    },
    dashboardContent() {
      return {
        width: "100%",
        height: "100%",
      };
    },
  },
  watch: {},
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type == "substation/addSubstationData") {
        const ad = state.substation.substationPowerData[mutation.payload.i]["2021"];
        const item = state.substation.items.find((i) => i.id == mutation.payload.i);
        const sumAreaOcc = Math.floor(item.area * (item.occupied / 100));
        const sumArea = Math.floor(ad.sum_cost_real / sumAreaOcc);
        this.kpis.totalPowerUsage += ad.power_sum;
        this.kpis.totalCostsSqm += sumArea;
        this.kpis.totalCosts += ad.sum_cost_real;
        this.kpis.allCosts.push(ad.sum_cost_real);
        //TODO fix 4
        this.kpis.avgCosts = this.kpis.totalCosts / 4;
        this.kpis.avgCosts_m += this.kpis.totalCostsSqm / 4;
      }
      // console.log(mutation);
      // console.log(state);
    });
  },
  beforeDestroy() {},
  methods: {},
};
</script>
<style scoped>
.bg-orange-txt-white {
  color: #fff;
  background-color: #f08a00;
  justify-content: center;
}
</style>
