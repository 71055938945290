<template>
<v-row>
  <v-col offset-md="3" md="6" cols="12">
    <v-alert prominent type="error">
      <v-row align="center">
        <v-col class="grow">
          {{ $t('errors.403') }}
        </v-col>
        <v-col class="shrink">
          <v-btn :to="{ name: 'home'}">Home</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-col>
</v-row>
</template>

<script>
export default {
  name: 'Error403'
}
</script>
