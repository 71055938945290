import Api from './Api'

export default {
  initCSRF () {
    return Api().get('/sanctum/csrf-cookie', {
      baseURL: process.env.VUE_APP_API_URL,
    })
  },
  login (jModel) {
    return Api().post('/auth/login', jModel)
  },
  refresh () {
    return Api().get('/auth/refresh')
  },
  logout (jModel) {
    return Api().post('/auth/logout', jModel)
  },
  me () {
    return Api().get('/auth/me')
  },
  update (jModel) {
    return Api().put('/auth/update', jModel)
  },
  resetPassword (jModel) {
    return Api().put('/auth/reset_password', jModel)
  },
  verifyResetLink (jModel) {
    return Api().post('/auth/verify_reset_link', jModel)
  },
  forgotPassword (jModel) {
    return Api().post('/auth/forgot_password', jModel)
  },
  register (jModel) {
    return Api().post('/auth/register', jModel)
  }
}
