<template>
  <div class="d-flex justify-center align-center" style="width: 100%; height: 100%">
    <v-progress-circular :size="100" :color="color" indeterminate></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
