<template>
  <v-list>
    <v-list-item-group :value="true" prepend-icon="mdi-home-group" v-model="model" multiple color="orange">
      <v-list-item v-for="(substation, i) in substations" :key="i" @click="toggleSubstation(substation)">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title style="font-size: 0.8em" v-text="substation.address"></v-list-item-title>
          <v-list-item-action-text v-text="substation.name"></v-list-item-action-text>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
export default {
  name: "NavigationList",
  data: () => ({
    model: [],
    subFilter: [
      { name: "00595", id: 15, save_co2: -13.8, save_oil: -28.6 },
      { name: "00596", id: 16, save_co2: -14.4, save_oil: -29.9 },
      { name: "00597", id: 17, save_co2: -13.2, save_oil: -27.4 },
      { name: "00598", id: 18, save_co2: -15.5, save_oil: -32.3 },
    ],
  }),
  computed: {
    substations() {
      const subs = this.$store.getters["substation/items"];

      const filtered = subs.filter((sub) => this.subFilter.map((s) => s.name).indexOf(sub.name) > -1);
      filtered.forEach((e) => {
        if (!e.area) {
          e.area = Math.floor(((Math.random() + 0.1) * (200 - 50 + 1) + 50) / 10) * 100;
        }
        //TODO add this serverside/modelside and change this whole mess
        if (!e.occupied) {
          if (!e.rented) {
            e.occupied = Math.floor((Math.random() * (100 - 50 + 1) + 50) / 10) * 10;
          } else {
            e.occupied = e.rented;
          }
        }
        if (!e.save_co2) {
          e.save_co2 = this.subFilter.find((i) => i.id == e.id).save_co2;
        }
        if (!e.save_oil) {
          e.save_oil = this.subFilter.find((i) => i.id == e.id).save_oil;
        }
      });
      if (filtered) {
        filtered.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      }
      return filtered;
    },
  },
  mounted() {},

  methods: {
    toggleSubstation(substation) {
      this.$store.dispatch("toggleSubstations", substation);
    },
  },
};
</script>
