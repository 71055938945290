// initial state
import AccountService from '@/services/AccountService'

const state = {
  user: {},
  config: {},
  isLoggedIn: false
}

// getters
const getters = {
  isLoggedIn (state) {
    return state.isLoggedIn
  },
  user (state) {
    return state.user
  },
  getUserName: (state) => state.user.name,
  userSettings: (state) => state.user.settings || {},
  config: (state) => state.config
}

// actions
const actions = {
  async login ({ dispatch }, logindata) {
    const response = await AccountService.login(logindata)
    await dispatch('checkResponse', { response })
  },
  async refresh ({ dispatch }) {
    try {
      const response = await AccountService.refresh()
      await dispatch('checkResponse', { response })
    } catch {
      // todo
    }
  },
  async logout ({ commit }) {
    await AccountService.logout()
    commit('logout')
  },
  async checkResponse ({ commit, dispatch }, payload) {
    const response = payload.response
    if (response && response.data) {
      await dispatch('me')
      commit('setIsLoggedIn', true)
    } else {
      commit('logout')
    }
  },
  async me ({ commit }) {
    const response = await AccountService.me()
    if (response.data) {
      commit('setUser', response.data.user)
      commit('setConfig', response.data.config)
    }
  },
  updateUserSettings({ commit }, settings) {
    commit('setUserSettings', settings)
  }
}

// mutations
const mutations = {
  setIsLoggedIn (state, newstate) {
    state.isLoggedIn = newstate
  },
  logout (state) {
    state.isLoggedIn = false
    state.user = {}
  },
  setUser (state, user) {
    state.user = user
  },
  setConfig (state, config) {
    state.config = config
  },
  setUserSettings (state, settings) {
    state.user = {
      ...state.user,
      settings: {
        ...settings
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
