<template>
  <div style="padding: 20px">
    <apexchart height="450" :options="chartOptions" :series="activeSubstationData" />
  </div>
</template>
<script>

export default {
  name: "SubstationBalanceChart",
  computed: {
    activeSubstationData() {
      const data = this.$store.state.substation.substationPowerData;
      const activeData = this.$store.getters["activeSubstations"];
      const chartData = [];
      activeData.forEach((ad) => {
        const d = data[ad.id]["2021"];
        let sumRate = 0;
        let subChartData = {
          name: ad.name,
          unit: "€",
        };
        let lineData = [];
        d.costs.real.forEach((cost) => {
          let item = d.costs.costs.find((c) => c.date == cost.date);
          if (item) {
            sumRate = item.balance;
          }

          lineData.push({ x: cost.date, y: sumRate - cost.cost_real });
        });
        subChartData.data = lineData;
        chartData.push(subChartData);
      });
      

      return chartData;
    },

    chartOptions() {
      return {
        //TODO use fixed colors for all the buildings
        // colors: ['#008FFB', '#00E396', '#00E300'],
        chart: {
          type: "line",
          
        },
        xaxis: {
          type: "datetime",
          min: "2021-01-01",
          max: "2021-12-31",
          labels: {
            datetimeUTC: false,
          },
        },
        yaxis: [
          {
            labels: {
              formatter: (val) => `${val.toFixed(2)} €`,
            },
          },
        ],
        tooltip: {
          x: {
            format: "dd.MM.",
          },

        },
      };
    },

    tooltipFormatY(value, options) {
      return `${value.toFixed(2)} ${this.chartSeries[options.seriesIndex].unit}`;
    },
  },
};
</script>
