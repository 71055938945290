<template>
  <div style="padding: 20px;">
    <apexchart
      type="line"
      height="500"
      :options="chartOptions"
      :series="chartSeries"
    />
  </div>
</template>

<script>
import SubstationService from '../../../services/SubstationService'

export default {
  name: 'SubstationCostsChart',
  props: {
    item: {
      type: Object,
      default () {
        return {
          name: ''
        }
      }
    }
  },
  mounted () {
    this.loadData()
  },
  data () {
    return {
      year: 2021,
      loading: false,
      dataPayment: [],
      dataCostsReal: [],
      dataCostsProg: [],
      maxCosts: 5000
    }
  },
  computed: {
    minDate () {
      return this.year + '-01-01'
    },
    maxDateStr () {
      return this.$moment().format('YYYY-MM-DD')
    },
    maxDate () {
      return this.year + '-12-31'
    },
    isMaxDate () {
      return this.date === this.maxDateStr
    },
    years () {
      const years = []
      for (let i = 2022; i > 2019; i--) {
        years.push(i)
      }
      return years
    },
    chartOptions () {
      return {
        // colors: ['#008FFB', '#00E396', '#00E300'],
        stroke: {
          curve: ['stepline', 'smooth', 'smooth'],
        },
        xaxis: {
          type: 'datetime',
          min: '2021-01-01',
          max: '2021-12-31',
          labels: {
            datetimeUTC: false
          }
        },
        yaxis: [
          {
            min: 0,
            max: (max) => {
              if (max>this.maxCosts)
                this.maxCosts = Math.ceil(max/1000)*1000;
              return this.maxCosts
            },
            labels: {
              formatter: (val) => `${val.toFixed(2)} €`
            }
          }
        ],
        tooltip: {
          x: {
            format: 'dd.MM.'
          },
          y: {
            formatter: this.tooltipFormatY
          }
        }
      }
    },
    chartSeries () {
      return [
        {
          name: this.$t('panels.substation.payments'),
          data: this.dataPayment,
          unit: '€'
        },
        {
          name: this.$t('panels.substation.costs'),
          data: this.dataCostsReal,
          unit: '€'
        },
        {
          name: this.$t('panels.substation.costs_prognose'),
          data: this.dataCostsProg,
          unit: '€'
        }
      ]
    }
  },
  watch: {
    year () {
      this.loadData()
    }
  },
  methods: {
    async loadData () {
      this.loading = true
      try {
        const response = await SubstationService.costs(this.item.id, this.year)

        this.prepareDataPayments(response.data.costs)
        this.prepareDataCostsReal(response.data.real)
        this.prepareDataCostsProg(response.data.prog)
      } catch (e) {
        console.log(e);
        this.dataPayment = []
        this.dataCostsReal = []
        this.dataCostsProg = []
      }
      this.loading = false
    },
    prepareDataPayments(data) {
      const dataPayment = []     
      for (const item of data) {
        const x = item.date
        dataPayment.push({
          x,
          y: item.balance
        })
      }
      this.dataPayment = dataPayment
    },
    prepareDataCostsReal(data) {
      const dataCostsReal = []
      for (const item of data) {
        const x = item.date
        dataCostsReal.push({
          x,
          y: item.cost_real
        })
      }
      this.dataCostsReal = dataCostsReal
    },
    prepareDataCostsProg(data) {
      const dataCostsProg = []
      for (const item of data) {
        const x = item.date
        dataCostsProg.push({
          x,
          y: item.cost_prog
        })
      }
      this.dataCostsProg = dataCostsProg
    },
    tooltipFormatY (value, options) {
      return `${value.toFixed(2)} ${this.chartSeries[options.seriesIndex].unit}`
    }
  }
}
</script>
