import Api from './Api'

export default {
  index () {
    return Api().get('/storages')
  },
  store (item) {
    return Api().post('/storages', { ...item })
  },
  update (item) {
    return Api().put('/storages/' + item.id, { ...item })
  },
  updateGeometry (id, geometry) {
    return Api().post(`/storages/${id}/geometry`, { geometry: geometry })
  },
  updateNode (id, nodeId, supply) {
    return Api().post(`/storages/${id}/node`, { node_id: nodeId, supply: supply })
  },
  delete (item) {
    return Api().delete('/storages/' + item.id)
  }
}
