<template>
    <dashboard-container />
</template>

<script>
  import DashboardContainer from './dashboard/DashboardContainer.vue'
  export default {
    name: 'Home',
    components: {
      DashboardContainer
    },
    data () {
      return {
      }
    }
  }
</script>
