// initial state
import NodeService from '@/services/NodeService'

const state = {
  items: [],
  panelErrors: false
}

// getters
const getters = {
  items: state => state.items,
  itemsMapped (state) {
    const map = new Map()
    for (const item of state.items) {
      map.set(item.id, item)
    }

    return map
  },
  panelErrors: state => state.panelErrors
}

// actions
const actions = {
  async index ({ commit }) {
    try {
      const response = await NodeService.index()
      commit('items', response.data.nodes)
    } catch (e) {
      console.error(e)
      commit('items', [])
    }
  },
  async saveItem ({ commit }, item) {
    const action = item.id !== undefined ? 'update' : 'store'
    const payload = {
      id: item.id,
      name: item.name,
      supply: item.supply
    }
    let errors = false
    try {
      const response = await NodeService[action](payload)
      commit('saveItem', response.data.node)
      if (response.data.edges !== undefined) {
        commit('edge/saveItems', response.data.edges, { root: true })
      }
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        console.error(e)
      }
    }

    return errors
  },
  async updateGeometry ({ commit }, payload) {
    let errors = false
    try {
      const result = await NodeService.updateGeometry(payload.id, payload.geometry)

      commit('saveItem', result.data.node)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        console.error(e)
      }
    }

    commit('panelErrors', errors)
    return errors
  },
  async deleteItem ({ commit }, item) {
    try {
      await NodeService.delete(item)
      commit('deleteItem', item)
    } catch (e) {
      console.error(e)
    }
  }
}

// mutations
const mutations = {
  items (state, items) {
    state.items = items
  },
  panelErrors (state, errors) {
    state.panelErrors = errors
  },
  saveItem (state, item) {
    const items = state.items

    const index = items.findIndex(t => t.id === item.id)
    if (index === -1) {
      items.push(item)
    } else {
      items[index] = item
    }

    state.items = [
      ...items
    ]
  },
  deleteItem (state, item) {
    const items = state.items

    state.items = items.filter(t => t.id !== item.id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
