import { clickStates } from "../../util/structs"

// initial state
const state = {
  clickState: clickStates.IDLE,
  clickOptions: {},
  component: undefined
}

// getters
const getters = {
  clickState: state => state.clickState,
  clickOptions: state => state.clickOptions,
  component (state) {
    return state.component
  }
}

// actions
const actions = {
  setComponent ({ commit }, component) {
    commit('component', component)
  },
  setClickState ({ commit }, payload) {
    commit('click', payload)
  }
}

// mutations
const mutations = {
  click (state, payload) {
    state.clickState = payload.clickState
    state.clickOptions = payload.clickOptions || {}
  },
  component (state, component) {
    state.component = component
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
